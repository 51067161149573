<template>
    <div class="container-fluid footer">
            <div class="row justify-content-center">
                <div class="col-auto">
                    <div class="menu">
                        <div class="item">добавить радио</div>
                        <div class="item">поделиться идеями</div>
                        <div class="item">поддержка проекта</div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto">2020 - NWR - Network World Radio</div>
            </div>
    </div>
</template>

<script>
export default {
    props: {
        // uploadListRadio: Array //* from json
    },

    data(){
        return {
            filterSelected: null, //* выбранная категория  
        }
    },

    computed: {
        
    },

    methods: {
         
    }
    
}
</script>



<style lang="sass">
@import "@/styles/common.sass"

.footer
    border: 1px solid #20272F
    background-color: $bg
    font-size: 14px
    text-transform: uppercase

    .menu
        display: flex
        .item
            padding: 15px

    // position: fixed
    // top: 0
    // left: 0
    // display: flex
    // min-height: 100px
    // width: 150px
    // z-index: 99
    // .cat
    //     cursor: pointer
    
</style>