<template>

    <div id="app">

        <headerbox/>

        <router-view class="mainContent"/>

        <div id="nav">
        <!-- <router-link to="/">Home</router-link> | -->
        <!-- <router-link to="/about">About</router-link> -->
        </div>

        <footerbox/>

        <audio id="stream" hidden="hidden" controls="controls" preload="auto" />

    </div>

</template>



<script>
import headerbox from "@/components/headerbox.vue"
import footerbox from "@/components/footerbox.vue"
import {mapActions} from "vuex"
import {mapGetters} from 'vuex'


export default {
    name: 'Home',
    data(){
        return {
            // uploadListRadio: [], //* загружаемый массив стримов
            // filteredListRadio: [],

            // htmlAudio: null,       //* audio элемент
            // htmlAudioSRC: null,    //* путь к стриму
            // htmlAudioVolume: 0.15, //* громкость
            // 5e794ec627235b6341a0233563b46df1a8b534b7
        }
    },
    components: {
        headerbox,
        footerbox
    },

    computed: {
        // return this.$store.getters.allRadio
        // ...mapGetters(["getStreamParameters"]),
    },

    methods: {
        ...mapActions(["fetchRadioList"]),

        // changeListRadio(arr){
        //     //* 
        //     this.filteredListRadio = arr
        // },

        // selectedRadio(item){
        //     this.htmlAudioSRC = item.url
        //     console.log(this.htmlAudioSRC)
        //     console.log(this.htmlAudio)

        //     setTimeout(function(){
        //         this.htmlAudio.play()
        //     }.bind(this), 1000)
        // },

    },

    watch: {
        // getStreamParameters(){
        //     console.log('watch');
            
        // }
    },
    // beforeMount
    async mounted(){
        //* ЗАГРУЗКА РАДИО СТРИМОВ
        // this.$store.dispatch('fetchRadioList')
        this.fetchRadioList()
        console.log('запуск загрузки стримов');
        
        //* АУДИО
        let audioTag = document.querySelector('#stream')
        this.$store.commit('setAudioHTML', audioTag)
        
    }
}
</script>



<style lang="sass">
@import "@/styles/common.sass"

#canvas 
    position: fixed
    left: 0
    top: 0
    width: 350px
    height: 150px



</style>