export default { 
    state: {
        radioLoadList: [],
        radioFilterList: [],
        radioCategory: [],
        radioShowCount: null,

        sortByPreset: 'unsorted',   //* unsorted, favorite, rating, alphabet, listener, random
        sortByStyle: 'all',       //* pop rock ...
        searchLine: '',
    },

    actions: {
        async fetchRadioList(ctx){
            //* загрузка на await'ах
            const res = await fetch('data.json')
            const radioList = await res.json()

            console.log(radioList);


            ctx.commit('updateRadioList', radioList)
        },

        filterRadioList(ctx, searchValue){
            let readyList = ctx.state.radioLoadList
            //* console.log(readyList)
            

            //* ФИЛЬТРАЦИЯ ПО СТИЛЮ
            let style = ctx.state.sortByStyle //* 
            

            if ( style !== 'all' ) {
                let newAr = new Set()
                readyList = readyList.filter(function(item){
                    // console.log(item);
                    //* учитываем несколько категорий в одной ячейке
                    item.category.split(' ').forEach( (type) => {
                        if (style == type) newAr.add(item)
                    })

                })
                console.log(`sort by style - ${style}`);

                //* преобразуем set в массив
                readyList = Array.from(newAr);
            }

            //* ФИЛЬТРАЦИЯ ПО НАЗВАНИЮ
            //* если в поиск чтото введено - ищем в ИСХОДНОЙ коллекции

            if ( ctx.state.searchLine.length > 0 ) { 
                readyList = readyList.filter( (item) => {
                    let str = item.title.toLowerCase()
                    let strSearch = ctx.state.searchLine.toLowerCase()
                    if ( str.indexOf(strSearch) > -1 )  return true   
                })
                console.log(readyList);
            } 

            //* СОРТИРОВКА ПО ПРЕСЕТУ
            // let sortBy = ctx.state.sortByPreset
            // console.log(readyList);
            
            // if ( sortBy !== 'unsorted' ) {
            //     switch (sortBy) {
            //         case 'rating':
            //             readyList = readyList.sort(function(a, b){
            //                 return a.rating - b.rating
            //             })
            //             break;

            //         case 'alphabet':
            //             readyList = readyList.sort()
            //             break;

            //         case 'listener':
            //             readyList = readyList.sort(function(a, b){
            //                 return a.listener - b.listener
            //             })
            //             break;
                
            //         default:
            //             console.log(`sort by DEFAULT`);

            //             break;
            //     }

            //     readyList.forEach((item) => {
            //         // console.log(item.rating);
                    
            //     })
            // }


            
            ctx.commit('filterRadioList', readyList)
        },



        getCategory(){
            //* создание категорий для фильтра
            let cat = new Set()
            cat.add('all')
            this.uploadListRadio.forEach( (item) => {
                //* в одной ячейке мб несколько категорий
                item.category.split(' ').forEach( (item) => {
                    if (item.length > 0) cat.add(item)
                })

            })
            console.log('aaaaaaaa');
            
            return cat
        }


    },

    mutations: {
        updateRadioList(state, radioList){
            state.radioLoadList = radioList
            state.radioFilterList = radioList
        },
        filterRadioList(state, radioList){
            state.radioFilterList = radioList
        },
        defaultRadioList(state, radioList){
            state.radioFilterList = state.radioLoadList
        },
        setFilterPreset(state, value){
            console.log(value);
            state.sortByPreset = value
        },
        setFilterStyle(state, value){
            console.log(value);
            state.sortByStyle = value
        },
        setSearchLine(state, value){
            state.searchLine = value
        }
    },

    getters: {
        //* трансформаци данных и получение из стора
        getRadioLoadList(state) {
            return state.radioLoadList
        },

        //* 
        getRadioList(state) {
            return state.radioFilterList
        },

        //* подсчет количества выбранных стримов
        getRadioListCount(state){
            // return 36
            return state.radioFilterList.length
        },

        //* получение категорий для фильтра
        getCategory(state){
            console.log('получение категорий');

            //* создание категорий для фильтра
            let cat = new Set()
            cat.add('all')
            state.radioLoadList.forEach( (item) => {
                //* в одной ячейке мб несколько категорий
                item.category.split(' ').forEach( (item) => {
                    if (item.length > 0) cat.add(item)
                })

            })
            console.log(cat);
            
            return cat
        },


    },
}

//*https://www.youtube.com/watch?v=c2SK1IlmYL8
//*23:37