<template>
    <div class="container header">
        <div class="row align-items-center">
            <div class="col-auto">
                <div class="logo"> </div>
            </div>
            <div class="col">
                <div class="soundControl">
                    <div class="play"
                    v-bind:class="{active: getAudioActive}"
                    v-on:click="playStream">

                    </div>
                    <div class="volume">
                        <input type="range" min="0" max="1" step="0.005"
                        v-bind:value="audioVolume"
                        v-on:change="changeVolume">
                    </div>
                </div>
            </div>
            <div class="col-auto ">
                <div class="menuBtn"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return {
            // audioVolume: 0.15
        }
    },

    computed: {
        ...mapGetters([ "getStreamParameters", "getAudioActive" ]),
        audioVolume() {
            return this.$store.state.soundControl.audioVolume;
        }
    },

    methods: {
        changeVolume(e){
            console.log(e);
            console.log(e.target.value);
            this.$store.commit('setAudioVolume', e.target.value)
            
        },
        playStream(value){
            console.log(value);
            
            this.$store.dispatch('playStream')
            // this.$store.commit('setStreamParameters', e.target.value)

        },
        ddd(){
            console.log('ident');
            
            var data = {
                'url': 'http://icecast.newradio.cdnvideo.ru/newradio3?1761',
                'return': 'timecode,apple_music,deezer,spotify',
                'api_token': 'test'
                }

            $.getJSON('https://api.audd.io/?jsonp=?', data, function(result){
                console.log(result);
            });
            
           

        },

        async fetchMusicIdentity(){
            var data = {
                'url': 'http://icecast.newradio.cdnvideo.ru/newradio3?1761',
                'return': 'timecode,apple_music,deezer,spotify',
                'api_token': 'test'
            }
            //* загрузка на await'ах
            const res = await fetch(data, {

            })
            const music = await res.json()
            console.log(music);
            
        }

    }
    
}
</script>



<style lang="sass">
    .header
        border: 1px solid #20272F
        padding-top: 64px 
        padding-bottom: 96px 
        .logo, .menuBtn,
            // border: 1px solid #fff
            background-size: contain
            background-position: center
            background-repeat: no-repeat 
        .logo
            background-image: url('/assets/img/logo-nwr.svg')  
            height: 90px
            width: 192px                
        .menuBtn
            background-image: url('/assets/img/menu-main.svg')  
            height: 50px
            width: 50px
        .soundControl
            display: flex
            align-items: center
            .play
                background-size: contain
                background-position: center
                background-repeat: no-repeat
                background-image: url('/assets/img/sound-play.svg')  
                height: 60px
                width: 60px
                margin: 0 15px 0 15px
                cursor: pointer
                &.active
                    background-image: url('/assets/img/sound-stop.svg')  


            .volume
                width: 100%
                margin: 0 15px 0 15px
                
</style>