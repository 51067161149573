export default { 
    state: {
        streamParameters: [{
            // streamUrl: '',           //* url текущего стрима 
            // audioActive: false      //* флаг включения стрима
        }],   

        audioHTML: '',          //* audio элемент
        // audioSRC: null,      //* путь к стриму
        audioVolume: 0.15,      //* громкость
        audioActive: false      //* флаг включения стрима

        
    },

    actions: {

        async playerPlay(){
            // this.audioElement.pause()
            this.loadingUrl = true
            this.audioElement.play()
            .then(data => {
                console.log('ppp');
                this.audioPlaying = true 
                this.loadingUrl = false
                
            })
            .catch(err => { 
                console.log(`err playerPlay - ${err}`) 
                console.log(this.audioElement);
                this.loadingUrl = false
                this.audioElement.pause()
                    
            })
        },




        playStream(ctx){
            console.log('action play stream');
            console.log(ctx.state.streamParameters.streamUrl);

            let stream = ctx.state.streamParameters
            let audioHTML = ctx.state.audioHTML

            //* запустить
            if ( audioHTML.src !== stream.streamUrl) {
                console.log('play');
                audioHTML.src = stream.streamUrl
                audioHTML.play()

                ctx.commit('setAudioActive', true)
                // stream.audioActive = true
            } else if ( audioHTML.src == stream.streamUrl && ctx.state.audioActive == true) { 
                console.log('pause');
                audioHTML.pause()
                // ctx.state.audioPlaying = false
                audioHTML.currentTime = 0 

                //* костыль - "обнуляет кеш" на аудио стриме
                //* заменить на ддобавление рандома к url
                let src = audioHTML.src
                audioHTML.src = ''
                audioHTML.src = src


                ctx.commit('setAudioActive', false)
                // stream.audioActive = false
            } else if ( audioHTML.src == stream.streamUrl && ctx.state.audioActive == false) {
                console.log('play 2');
                audioHTML.play()

                ctx.commit('setAudioActive', true)
                // stream.audioActive = true
            }

            // function playerStop(){
            //     audioHTML.pause()
    
            //     this.audioPlaying = false
            //     this.audioElement.currentTime = 0 
    
            //     //* костыль - "обнуляет кеш" на аудио стриме
            //     //* заменить на ддобавление рандома к url
            //     let src = this.audioElement.src
            //     this.audioElement.src = ''
            //     this.audioElement.src = src
            // },

            

        },
        // stopStream(ctx){
        //     ctx.state.audioHTML.pause()

        //     // ctx.commit('filterRadioList', readyList)
        // },
 
    },

    mutations: {
        //* установить флаг состояния стрима on/off
        setAudioActive(state, toggle){
            state.audioActive = toggle
        },

        setStreamParameters(state, stream){
            // Vue.set(state, 'streamParameters', stream)
            state.streamParameters = stream
        },

        setAudioHTML(state, tag){
            state.audioHTML = tag
        },

        setAudioVolume(state, volume){
            //! сделать громкость через state !
            state.audioHTML.volume = volume
            // state.audioHTML.volume = volume
        },
    },

    getters: {
        //* возвращает информацию по текущему стриму
        getStreamParameters(state) {
            
            return state.streamParameters
        },

        //* возвращает информацию по текущему стриму
        getAudioActive(state) {
            return state.audioActive
        },

        //* возвращает громкость
        getAudioVolume(state) {
            console.log(state.audioVolume);
            
            return state.audioVolume
        },

        // //* получение url текущего стрима - для выделения активного стрима
        // getUrlActiveStream(){
        //     return 
        // }
    },
}
