<template>
    <div class="container searchRadio">

        <div class="row justify-content-end">
            <div class="col-auto">{{getRadioListCount}} стримов найдено</div>
        </div>
        <div class="row justify-content-center">
            <div class="col">
                <input type="text" class="inpSearch"
                placeholder="НАЧНИТЕ ВВОДИТЬ НАЗВАНИЕ РАДИО"
                v-on:input="changeSearchLine">
            </div>
        </div>
        <!-- <div class="row justify-content-left">
            <div class="col">
                <div class="filter">
                    <div class="btnShowFilterPanel"></div>   
                    <div class="item" v-on:click="selectPreset('favorite')">избранное</div>             
                    <div class="item" v-on:click="selectPreset('rating')">рейтинг</div>             
                    <div class="item" v-on:click="selectPreset('alphabet')">алфавит</div>             
                    <div class="item" v-on:click="selectPreset('listener')">слушатели</div>             
                    <div class="item" v-on:click="selectPreset('random')">случайное</div> 
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <div class="musicStyle">
                    <div class="musicStyleItem" 
                    v-bind:class="{active: selectCategories == cat}"
                    v-for="(cat, i) in getCategory" :key="i"
                    v-on:click="selectCategory(cat)">
                    {{cat}}</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {

    data(){
        return {
            filterSelected: null, //* выбранная категория  
            selectCategories: ''
        }
    },

    computed: {
        ...mapGetters(["getRadioListCount", "getCategory"]),

        //* список категорий для рендера фильтра
        // filterCats(){
        //     //* создание категорий для фильтра
        //     let cat = new Set()
        //     console.log( typeof(this.uploadListRadio) );
        //     cat.add('all')
        //     this.uploadListRadio.forEach( (item) => {
        //         //* в одной ячейке мб несколько категорий
        //         item.category.split(' ').forEach( (item) => {
        //             if (item.length > 0) cat.add(item)
        //         })

        //     })
        //     console.log('aaaaaaaa');
            
        //     return cat
        // }
    },

    methods: {
        changeSearchLine(e){
            this.$store.commit('setSearchLine', e.target.value)
            this.$store.dispatch('filterRadioList')
        },

        selectPreset(value){
            this.$store.commit('setFilterPreset', value)
            this.$store.dispatch('filterRadioList')
        },

        selectCategory(value){
            //* toggle 
            ( this.selectCategories !== value ) ? ( this.selectCategories = value ) : ( this.selectCategories = 'all' );

            //* 
            this.$store.commit('setFilterStyle', this.selectCategories)

            //* запуск фильтрации
            this.$store.dispatch('filterRadioList')
        },

        // selectCat(cat){
        //     console.log( cat )


            // //* формируем новую коллекцию
            // let array
            // let newAr = new Set()
            // if (cat !== 'all') {
            //     this.uploadListRadio.forEach( (item) => {

            //         //* учитываем несколько категорий в одной ячейке
            //         item.category.split(' ').forEach( (type) => {
            //             if (cat == type) newAr.add(item)
            //         })

            //     })
            //     //* преобразуем set в массив
            //     array = Array.from(newAr);
            //     console.log(array);

            // } else {
            //     array = this.uploadListRadio
            // }
           
            
            // this.$emit('changeListRadio', array)
        // },

    }
    
}
</script>



<style lang="sass">
@import "@/styles/common.sass"

.searchRadio
    // border: 1px solid $mainelem
    .inpSearch
        border: 3px solid $mainelem
        border-radius: 7px
        color: #fff
        font-size: 20px

        background-color: transparent
        padding: 25px

        width: 100%
        height: 80px
        &::placeholder
            // color: #fff
            // font-size: 24px
            // padding: 15px
    .filter
        display: flex
        align-items: center
        padding: 20px
        .btnShowFilterPanel
            background-image: url(/assets/img/filter.svg)
            background-repeat: no-repeat
            background-size: contain
            background-position: center
            margin: 0 20px 0 0
            min-height: 45px
            min-width: 45px
            cursor: pointer
        .item
            padding: 0 20px 0 20px
            cursor: pointer            
    .musicStyle
        display: flex
        flex-wrap: wrap
        padding: 0 15px 0 20px

        .musicStyleItem
            // border: 1px solid $mainelem

            // display: flex
            // justify-content: space-between
            // flex-direction: row
            margin-right: 15px
            cursor: pointer
            &.active
                color: $accent

</style>