import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import Meta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(Meta)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

store.subscribe((mutation, state) => {
    // console.log(`mut ${mutation.type}`);
    
    // console.log(mutation.type)
    // console.log(mutation.payload)
})