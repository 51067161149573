import Vue from 'vue'
import Vuex from 'vuex'

import getRadioList from './modules/getRadioList' 
import soundControl from './modules/soundControl' 

Vue.use(Vuex) //* use для регистрации нового плагина

export default new Vuex.Store({

    modules: {
        //* для декомпозиции логики
        getRadioList,
        soundControl,

    }

})
