<template>
    <div class="mainPage">

        <searchRadio/>

        <div class="container mainContent">

            <div class="row">
                
                <div class="col-6 col-md-4 col-lg-3 col-xl-2"
                    v-for="(item, i) in getRadioList" :key="i">

                    <div class="radioBox__wrapper">
                        <div class="radioBox__content"
                        v-bind:class="{active: item.streamUrl == getStreamParameters.streamUrl}">
                            <div class="top">
                                <!-- <div class="listener">
                                    <div class="count">{{item.listener}}</div>
                                </div> -->
                            </div>
                            <div class="name"
                            v-on:click="selectRadio(item)">
                                <div class="title">{{item.title}}</div>
                                <div class="subtitle">
                                    {{ (item.subtitle == 0) ? item.subtitle='' : item.subtitle }}
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="likes">
                                    <div class="like">{{item.rating}}</div>
                                </div>
                                <router-link class="enter" 
                                :to="{name: 'radio', params: {url: item.url, item: item} }" />
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>

        </div>
        
        <article class="container article">
            <div class="row">
                <div class="col-md-9 col-lg-6">
                    <div class="articleTitle">
                        <h1>nwr - онлайн радиостанции мира</h1>
                    </div>
                    <div class="articleText">
                        <p>NWRadio - это развивающийся проект, объединяюший в себе онлайн радиостанции России - а в перспективе со всех уголков планеты. Мы стараемся находить и транслировать аудио потоки в максимальном качестве.</p>
                        <p>Мы планируем сгрупировать все известные нам радиостанции по музыкальным направлениям, по геолокации, по стили вещания. Каждый пользователь сможет отметитить любимые радио и на основе выбора тысяч посетителей будут сформированы ТОП листы по музыкальным направлениям.</p>
                        <p>Добавляйте известные Вам радиопотоки, через специальную страницу. После модерации мы обязательно добавим Ваше радио в наш каталог.</p>
                        <p>Выбирайте любимую радиоволну и наслаждайтесь бесплатно!</p>
                    </div>
                    
                </div>
            </div>
        </article>
        
        <article class="container article">
            <div class="row">
                <div class="col-md-9 col-lg-6">
                    <div class="articleTitle">
                        <h1>Как поддержать проект?</h1>
                    </div>
                    <div class="articleText">
                        <p>Данный проект существует на не коммерческой основе, поэтому мы будем рады любым лучам поддержки от Вас. При желании поддержать проект можно:</p>
                        <ul>
                            <li>порекомендовать нас друзьям в социальных сетях</li>
                            <li>добавить в наш каталог новые радио станции</li>
                            <li>поделиться идеями по развития проекта</li>
                            <li>купить нам кофе или пиво :)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>

    </div>
</template>

<script>
import searchRadio from "@/components/searchRadio.vue"
import {mapGetters} from 'vuex'

export default {
    name: 'mainPage',
    components: {
        searchRadio,
    },

    data(){
        return {
            // selectedStream: null,
            // searchString: '',
            // findStationCount: 0,
        }
    },

    computed: {
        // return this.$store.getters.allRadio
        ...mapGetters(["getRadioList", "getRadioListCount", "getStreamParameters"]),
    },

    methods: {

        selectRadio(item){
            this.$store.commit('setStreamParameters', item)
            this.$store.dispatch('playStream')
        },
 
    },

    mounted(){


    },

    watch: {

    },

    metaInfo: {
        title: 'NWRadio - слушай радио онлайн',
        htmlAttrs: {
            lang: 'ru',
            // amp: true
        },
        meta: [
            { name: 'description', content: 'NWRadio.ru - это более 800 онлайн радиостанций объединенных удобным поиском и настройками окружения' },
            { name: 'keywords', content: 'слушать радио онлайн' },
            // { vmid: 'description', property: 'description', content: 'Vue App' },
        ],
        link: [
            { rel: 'canonical', href: 'https://nwradio.ru'}
        ]
    },


}
</script>

<style lang="sass" scoped>
@import "@/styles/common.sass"

.mainPage
    // position: relative
    // border: 1px solid #fff
    .mainContent
        min-height: 100vh
        // border: 1px solid #fff

    .row
 
        .radioBox__wrapper
            // border: 1px solid #fff
            position: relative
            width: 100%
            padding-bottom: 100% //*хак на пропорции div'a
            margin: 15px 0 15px 0
            .radioBox__content
                border: 3px solid $mainelem
                border-radius: 7px
                position: absolute
                display: flex
                flex-direction: column
                justify-content: center
                justify-content: space-between
                padding: 15px
                height: 100%
                width: 100%
                transition: .251s

                &:hover
                    border: 3px solid #fff
                    color: #fff
                    transition: .451s
                &.active
                    border: 3px solid $accent
                    transition: .451s
                    
                .top
                    display: flex
                    justify-content: flex-end
                    .listener
                        // border: 1px solid $mainelem
                        position: relative
                        display: flex
                        justify-content: center
                        align-items: center
                        background-image: url(/assets/img/listener.svg)
                        background-position: center
                        background-size: contain
                        background-repeat: no-repeat
                        width: 15px
                        height: 15px    
                        .count
                            // border: 1px solid $mainelem
                            position: absolute
                            font-family: 'number'
                            font-size: 14px
                            font-weight: bold
                            
                            color: $mainelem

                            // top: 27px
                            left: -15px
                .name
                    display: flex
                    justify-content: center
                    flex-direction: column
                    align-items: center
                    color: $accent
                    font-family: 'number', sans-serif 
                    text-transform: uppercase
                    .title
                        font-size: 28px
                        line-height: 34px
                        text-align: center
                    .subtitle    
                        font-size: 18px
                        line-height: 24px
                        text-align: center

                .bottom
                    display: flex
                    justify-content: space-between    
                    // border: 1px solid #fff
                    opacity: .5
                    .likes, .enter
                        // background-image: url(/assets/img/listener.svg)
                        background-position: center
                        background-size: contain
                        background-repeat: no-repeat
                        width: 15px
                        height: 15px
                    .likes
                        position: relative
                        background-image: url(/assets/img/like.svg)
                        .like
                            position: absolute
                            left: 20px    
                            top: -2px
                            font-size: 14px

                    .enter
                        background-image: url(/assets/img/enter.svg)    

    .article
        margin: 64px 0 32px 0 
        .articleTitle
            padding: 0 0 32px 0   
        .articleText
            text-align: justify  

    // .searchLayout
    
//     // display: flex
//     // align-items: center
//     // padding: 15px 0 15px 0    
//     text-align: right
//     .inpSearch
//         background-color: transparent
//         border: 1px solid #fff
//         color: $elem1
//         width: 100%

</style>